<template>
  <div class="details">
      <!-- 头部 -->
      <div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="@/assets/images/record/tianjin.jpg">
                </div>
				<div class="con_top_right">
					<h2>天津市增值电信业务网络安全防护定级备案</h2>
					<p class="new_tit">1、服务团队从业经验丰富  2、项目可加急办理； 3、可提供符合审批的建站服务 4、承接全国业务</p>
                    <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">{{price}}</span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                            <p>
                                从网络安全专业角度帮助企业开展通信网络单元的定级备案、符合性测评、安全风险评估等工作，协助企业依法依规开展电信业务运营。
                            </p>
                        </div>
					</div>

                    <div class="price_box">
                        <div class="box_left">
                            <p>分</p><p>类</p>
                        </div>
                        <div class="box_right">
                            <ul>
                                <li @click="price = 8000">自测评（二级）</li>
                                <li @click="price = 10000">自测评（三级）</li>
                                <li @click="price = 18000">自测评（二级+渗透）</li>
                                <li @click="price = 20000">自测评（二级+渗透）</li>
                                <li @click="price = 30000">第三方测评（二级）</li>
                                <li @click="price = 40000">第三方测评（三级）</li>
                            </ul>
                        </div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>
    
        <div class="conten_body">  
			<div class="conten_left">
                <!-- 应用场景 -->
                <div class="scenario">
                    <div class="scenario_title">
                        <div class="title_img">
                            <img src="@/assets/images/record/001.png" alt="">
                        </div>
                        <h3>天津市增值电信业务网络安全防护定级备案</h3>
                    </div>
                    <div class="scenario_content">
                        <div class="image">
                            <img src="@/assets/images/record/01.jpg" alt="">
                        </div>
                        <h4>应用场景</h4>
                        <div class="lists">
                            <ul>
                                <li>1.天津市内提供公共互联网信息服务的基础电信企业、域名服务机构、云平台服务提供商、APP运营企业、车联网平台企业、工业互联网平台和标识解析节点企业等。</li>
                                <li>2.通信网络基础设施、公共云服务平台、域名服务系统、工业互联网平台、标识解析系统、车联网应用服务平台、网约车信息服务平台等。</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- 业务开展 -->
                <div class="business">
                    <div class="business_title">
                        <div class="title_img">
                            <img src="@/assets/images/record/001.png" alt="">
                        </div>
                        <h3>业务开展的必要性</h3>
                    </div>
                    <div class="busi_lists">
                        <ul>
                            <li>1.根据《通信网络安全防护管理办法》法规的要求。</li>
                            <li>
                                <div class="busi_img" style="width:802px;height:369px">
                                    <img src="@/assets/images/record/same-1.jpg" alt="">
                                </div>
                            </li>
                            <li>
                                <div class="busi_img" style="width:802px;height:306px">
                                    <img src="@/assets/images/record/same-2.jpg" alt="">
                                </div>
                            </li>
                            <li>2.2021年，天津市通管局发布《关于加强增值电信企业网络与数据安全管理工作的通知》</li>
                            <li>
                                <div class="busi_img" style="width:834px;height:392px">
                                    <img src="@/assets/images/record/tianjin-1.jpg" alt="">
                                </div>
                            </li>
                            <li>3.企业需通过工信部“通信网络安全防护管理系统”中填报定级备案报告。
                           <li>4.企业需自行或委托专业机构启动本企业定级备案工作。
                            <li>5.企业定级备案后的系统均需做符合性测评和风险评估，其中安全等级为二级的系统，每两年做一次符合性测评和风险评估，安全等级为三级的系统，每年做一次符合性测评和风险评估。</li>
                            <li>6.对未按要求落实定级备案、符合性评测、安全风险评估等责任的企业，以及在评审中弄虚作假的，通管局将依法给予行政处罚，并纳入电信业务经营不良名单。</li>
                        </ul>
                    </div>
                </div>

                <!-- 客户收益 -->
                <div class="profit">
                    <div class="profit_title">
                        <div class="title_img">
                            <img src="@/assets/images/record/001.png" alt="">
                        </div>
                        <h3>客户收益</h3>
                    </div>
                    <div class="profit_content">
                        <ul>
                            <li>
                                <div class="profit_img">
                                    <img src="@/assets/images/record/one.png" alt="">
                                </div>
                                <h4>依据标准对被评估系统开展定级备案工作</h4>
                            </li>
                            <li>
                                <div class="profit_img">
                                    <img src="@/assets/images/record/two.png" alt="">
                                </div>
                                <h4>深度检测并出具符合标准规范的符合性评测报告及证明依据</h4>
                            </li>
                            <li>
                                <div class="profit_img">
                                    <img src="@/assets/images/record/three.png" alt="">
                                </div>
                                <h4>深度检测并出具符合标准规范的风险评估报告及证明依据</h4>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 产品详细 -->
                <div class="detailed">
                    <div class="detailed_title">
                        <div class="title_img">
                            <img src="@/assets/images/record/001.png" alt="">
                        </div>
                        <h3>客户收益</h3>
                    </div>
                    <div class="detaild_content">
                        <div class="detaild_one">
                            <h3>1.评估依据</h3>
                            <ul>
                                <li>1.《中华人民共和国网络安全法》</li>
                                <li>2.《中华人民共和国电信条例》</li>
                                <li>3.《电信业务经营许可管理办法》</li>
                                <li>4.YD/T 1728-2008电信网和互联网安全防护管理指南</li>
                                <li>5.YD/T 3799-2020电信网和互联网网络安全防护定级备案实施指南</li>
                                <li>6.《电信网和互联网安全防护基线配置要求及检测要求》系列标准</li>
                            </ul>
                        </div>
                        <div class="deta">
                        <div class="detaild_two">
                            <h3>2.服务内容</h3>
                            <ul>
                                <li>1.通信网络单元定级备案工作落实情况 </li>
                                <li>2.符合性评测和安全风险评估工作落实情况</li>
                            </ul>
                        </div>
                        <div class="detaild_three">
                            <h3>3.交付成果</h3>
                            <ul>
                                <li>1.定级报告</li>
                                <li>2.符合性测评报告</li>
                                <li>3.风险评估报告</li>
                                <li>4.符合性测评证明文件</li>
                                <li>5.风险评估证明文件 </li>
                            </ul>
                        </div>
                        </div>
                        <div class="detaild_four">
                            <h3>4.服务流程</h3>
                            <ul>
                              <li>签订合同</li>
                              <li><img src="@/assets/images/record/002.png" alt=""></li>
                              <li>信息基本情况调查</li>
                              <li><img src="@/assets/images/record/002.png" alt=""></li>
                              <li>测评实施 <br>
                                  （符合性测试、风险评估测试等）</li>
                              <li><img src="@/assets/images/record/002.png" alt=""></li>
                              <li>整改</li>
                              <li><img src="@/assets/images/record/002.png" alt=""></li>
                              <li>复评 <br>
                                  （渗透测试需要）</li>
                              <li><img src="@/assets/images/record/002.png" alt=""></li>
                              <li>出具符合标准规范的报告</li>
                              
                            </ul>
                        </div>
                        <div class="detaild_five">
                             <h3>5.时间周期</h3>
                            <p>甲方提供完整调查信息后的1周内完成</p>
                            
                        </div>
                    </div>
                </div>

                <!-- 我们的优势 -->
                <div class="our">
                    <div class="our_title">
                        <div class="title_img">
                            <img src="@/assets/images/record/001.png" alt="">
                        </div>
                        <h3>我们的优势</h3>
                    </div>
                    <div class="our_content">
                        <div class="our_book">
                            <div class="book_left">
                                <ul>
                                    <li>● 国家信息安全服务资质(安全工程类)</li>
                                    <li>● 国家信息安全风险评估资质</li>
                                    <li>● 国家通信网络安全服务风险评估资质</li>
                                    <li>● 国家信息安全应急处理服务资质</li>
                                    <li>● 国家信息系统安全集成服务资质</li>
                                    <li>● 国家信息安全漏洞库支持单位</li>
                                    <li>● 国际信息安全管理体系ISO 27001认证</li>
                                    <li>● 国际质量管理体系ISO9001认证</li>
                                    <li>● 国际环境管理体系ISO 14001认证</li>
                                    <li>● 高新技术企业</li>
                                    <li>● 软件企业</li>
                                    <li>● 企业研究开发机构证书</li>
                                </ul>
                            </div>
                            <div class="book_right">
                                <img src="@/assets/images/record/qualifications.jpg" alt="">
                            </div>
                        </div>
                        <div class="our_lists">
                            <ul>
                                <li>1.安全博士领衔多位获得国际和国内安全资质的安全专家为您服务。</li>
                                <li>2.专注网络信息安全领域10年以上，熟悉监管部门对政策法规的具体落地要求。</li>
                                <li>3.公司资质：具备国家网络信息安全主管部门颁发的权威安全认证资质。</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- 典型案例 -->
                <div class="case">
                    <div class="case_title">
                        <div class="title_img">
                            <img src="@/assets/images/record/001.png" alt="">
                        </div>
                        <h3>典型案例</h3>
                    </div>
                    <div class="case_content">
                    <div class="case_img">
                        <img src="@/assets/images/record/list.jpg" alt="">
                    </div>
                    <div class="case_lists">
                        <ul>
                            <li>● 1.天津坚果某某公司-坚果游戏增值电信业务系统定级备案服务项目 </li>
                            <li>● 2.杭州某鸽科技有限公司-某鸽科技增值电信业务系统定级备案服务项目 </li>
                            <li>● 3.江西搜某-某章头条增值电信业务系统定级备案服务项目 </li>
                            <li>● 4.江西某顺-某顺物流（万车帮）信息服务平台增值电信业务系统定级备案服务项目 </li>
                            <li>● 5.江西盈科某某某通智慧社区系统增值电信业务系统定级备案服务项目 </li>
                            <li>● 6.上海蓝某花公司（移动互联网联网应用系统）定级备案服务项目（三级） </li>
                            <li>● 7.上海喜某电子商务有限公司-喜某电子商务系统定级备案服务项目 </li>
                            <li>● 8.上海某娱-宽某山APP系统增值电信业务系统定级备案服务项目 </li>
                            <li>● 9.广东某城网络科技有限公司-增值电信业务系统定级备案业务 </li>
                            <li>● 10.深圳市某某世界卫星通信科技有限公司-增值电信业务系统定级备案业务 </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>

            <div class="conten_right">
                <div class="phone">
                    <div class="phone_left">
                        <p>服务热线(微信同号)：</p>
                        <p>188 188 60088</p> 
                        <p>188 188 63388</p>
                    </div>
                    <div class="phone_right">
                        <img src="@/assets/images/app/code.png" alt="">
                    </div>
                </div>
                <div class="hot">
                    <div class="hot_title">
                        <h4>热门产品</h4>
                    </div>
                        <div class="hot_lists">
                            <ul>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/jiangsu">
                                            <img src="@/assets/images/record/jiangsu.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>江苏省增值电信业务</p>
                                        <p>1.服务团队从业经...</p>
                                        <p>￥8000-40000</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/guangdong">
                                            <img src="@/assets/images/record/guangdong.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>广东省增值电信业务</p>
                                        <p>1.服务团队从业经...</p>
                                        <p>￥8000-40000</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
        </div>

  </div>
</template>

<script>
export default {
     data(){
        return{
            price:"8000-40000"
        }
    }

}
</script>

<style type="text/css" lang="less" scoped>
.scenario{
    margin-top: 30px;
    .scenario_title{   
        clear: both;     
        .title_img{
            float: left;
            width: 45px;
            height: 45px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        h3{
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            line-height: 45px;
        }
    }
    .scenario_content{
        margin-top: 40px;
        position: relative;
        .image{
            width: 850px;
            height: 352px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        h4{
            width: 88px;
            position: absolute;
            left: 0px;
            right: 0px;
            top: 10px;
            margin: auto;
            font-size: 22px;
            color: white;
        }
        .lists{
            position:absolute;
            width: 328px;
            right: 37px;
            bottom: 44px;
            margin: auto;
            ul{
                display: flex;
                flex-direction: column;
                li{
                    font-size: 18px;
                    line-height: 20px;
                    color: #FFFFFF;
                  
                }
            }
        }
    }
}

.business{
     margin-top: 30px;
    .business_title{
        clear: both;
        .title_img{
            width: 45px;
            height: 45px;
            float: left;
            img{
                width: 100%;
                height: 100%;
            }
        }
        h3{
            text-align: center;
            line-height: 45px;
            font-size: 24px;
            font-weight: bold;
        }
    }
    .busi_lists{
        margin-top: 40px;
        ul{
            width: 828px;
            li{
                font-size: 18px;
                line-height: 20px;
                .busi_img{
                    width: 802px;
                    height: 681px;
                    margin: 50px 10px;
                       box-shadow:  0px 4px 10px #E2E2E2;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.profit{
   margin-top: 50px;
    .profit_title{
        clear: both;
        .title_img{
            width: 45px;
            height: 45px;
            float: left;
            img{
                width: 100%;
                height: 100%;
            }
        }
        h3{
            text-align: center;
            line-height: 45px;
            font-size: 24px;
            font-weight: bold;
        }
    }  
    .profit_content{
        margin-top: 50px;
        width: 850px;
        height: 390px;
        background-color: #0144AD;
        ul{
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 390px;
            li{
                width: 250px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .profit_img{
                    width: 170px;
                    height: 170px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                h4{
                    margin-top: 45px;
                    font-size: 18px;
                    line-height: 20px;
                    color: #FFFFFF;
                    text-align: center;
                }
            }
        }
    }
}

.detailed{
    margin-top: 50px;
    .detailed_title{
        clear: both;
        .title_img{
            width: 45px;
            height: 45px;
            float: left;
            img{
                width: 100%;
                height: 100%;
            }
        }
        h3{
            text-align: center;
            line-height: 45px;
            font-size: 24px;
            font-weight: bold;
        }
    }
    .detaild_content{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 850px;
        height: 752px;
        border: 1px solid #EAEAEA;
        margin-top: 30px;
        .detaild_one{
            margin: 23px 38px 0px 68px;
            width: 321px;
            height: 351px;
            background-color: #0144AD;
            h3{
                margin-top: 20px;
                text-align: center;
                font-size: 18px;
                color: white;
            }
            ul{
                width: 100%;
                margin-top: 60px;
                li{
                    font-size: 16px;
                    line-height: 18px;
                    color: white;
                    padding: 0 30px;
                }
            }
        }
        .deta{
            width: 365px;
            height: 351px;
            margin-top: 23px;
            display: flex;
            flex-direction: column;
            .detaild_two{
                width: 365px;
                height: 168px;
                background-color: #0144AD;
                h3{
                    margin-top: 20px;
                    text-align: center;
                    font-size: 18px;
                    color: white;
                }
                 ul{
                    width: 100%;
                    margin-top: 40px;
                    li{
                        font-size: 16px;
                        line-height: 18px;
                        color: white;
                        padding: 0 30px;
                    }
                }
            }
            .detaild_three{
                margin-top: 32px;
                width: 365px;
                height: 178px;
                background-color: #0144AD;
                h3{
                    margin-top: 20px;
                    text-align: center;
                    font-size: 18px;
                    color: white;
                }
                 ul{
                    width: 100%;
                    margin-top: 24px;
                    li{
                        font-size: 16px;
                        line-height: 18px;
                        color: white;
                        padding: 0 30px;
                    }
                }
            }
        }
        .detaild_four{
            width: 724px;
            height: 178px;
            margin: 28px 58px 32px 68px;
            background-color: #0144AD;
            h3{
                margin-top: 20px;
                text-align: center;
                font-size: 18px;
                color: white;
            }
            ul{
                margin-top: 50px;
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-around;
                li{
                    text-align: center;
                    font-size: 16px;
                    line-height: 18px;
                    color: white;
                    margin: 0 5px;
                    img{
                        width: 16px;
                        height: 16px;
                    }
                }

            }
        }
        .detaild_five{
            width: 724px;
            height: 113px;
            margin-left: 68px;
            background-color: #0144AD;
            h3{
                margin-top: 20px;
                text-align: center;
                font-size: 18px;
                color: white;
            }
            p{
                margin-top: 23px;
                text-align: center;
                font-size: 16px;
                color: white;
            }
        }
    }
}

.our{
    margin-top: 50px;
    .our_title{
        clear: both;
        .title_img{
            width: 45px;
            height: 45px;
            float: left;
            img{
                width: 100%;
                height: 100%;
            }
        }
        h3{
            text-align: center;
            line-height: 45px;
            font-size: 24px;
            font-weight: bold;
        }
    }
    .our_content{
        width: 768px;
        background: #0144AD;
        margin-top: 50px;
        margin-left: 46px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        .our_book{
            width: 764px;
            background: white;
            display: flex;
            justify-content: space-around;
            border: 2px solid #0144AD;
            border-radius: 15px;
            .book_left{
                margin-top: 27px;
                ul{
                    li{
                        font-size: 18px;
                        line-height: 20px;
                    }
                }
            }
            .book_right{
                width: 315px;
                height: 316px;
                margin: 16px 0;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .our_lists{
           
            padding: 35px 55px 38px 80px;

            ul{
                li{
                    font-size: 18px;
                    line-height: 20px;
                    color: white;
                }
            }
        }
    }
}

.case{
     margin-top: 50px;
    .case_title{
        clear: both;
        .title_img{
            width: 45px;
            height: 45px;
            float: left;
            img{
                width: 100%;
                height: 100%;
            }
        }
        h3{
            text-align: center;
            line-height: 45px;
            font-size: 24px;
            font-weight: bold;
        }
    }
    .case_content{
        margin-top: 50px;
        position: relative;
        width: 852px;
        height: 400px;
        .case_img{
            width: 850px;
            height: 400px;
            img{
                width: 100%;
                height:100%;
            }
        }
        .case_lists{
            width: 740px;
            height: 180px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            ul{
                li{
                    font-size: 18px;
                    line-height: 22px;
                    color: white;
                }
            }
        }
    }
}


.conten_right{
	width:300px;
	background:#f4f4f4;
	float:left;
  display: block;
  min-height: 100%;
	margin-left:20px;
    .phone{
        width: 100%;
        height: 100px;
        background-color: #00A9F2;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .phone_left{
            p{
                font-size: 16px;
                line-height: 18px;
                color: white;
            }
        }
        .phone_right{
            width: 75px;
            height: 75px;
            img{
                width:100%;
                height: 100%;
                }
        }
    }
    .hot{
        margin-top: 30px;
        background-color: #F2F2F2;
        height: 380px;
        .hot_title{
            width: 300px;
            height: 35px;
            background: #00A9F2;
            h4{
                margin-left: 20px;
                font-size: 18px;
                line-height: 35px;
                color: white;
            }
        }
        .hot_lists{
            ul{
                li{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    .hot_left{
                        width: 147px;
                        height: 116px;
                        img{
                            width:100%;
                            height:100%;
                        }
                    }
                    .hot_right{
                        width: 140px;
                        margin-left: 20px;
                        p{
                            font-size: 14px;
                            line-height: 25px;
                             white-space: nowrap;    
                            overflow: hidden;         
                            text-overflow: ellipsis;
                        }
                        :nth-child(1){
                            padding-right: 15px;
                            font-weight: bold;
                           
                        }
                        :nth-child(2){
                             padding-right: 20px;
                            font-size: 13px;
                            font-weight: bold;
                             
                        }
                         :nth-child(3){
                            font-size: 16px;
                            color: #B20909;
                           
                        }
                    }
                }
            }
        }
    }

}





.details{
	width:1200px;
	margin:0 auto;
}
.contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background-color:#00A9F2;
	line-height: 45px;
	font-size:16px;
	color:#fff;
    
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:10px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #FF0000;
	line-height: 47px;
    padding-left: 45px;
    padding-right: 10px;
}
.pri_box  .price{
	font-size: 35px;
	font-weight: bold;
	color: #FF0000;
	position: relative;
	top:3px;
}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 15px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
        p{
            font-size: 14px;
            line-height: 16px;
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            li{
                font-size: 14px;
                line-height: 30px;
                color: #333333;
                background-color: #F2F2F2;
                padding: 0 5px;
                margin-right: 10px;
                margin-bottom: 10px;
            }
            :hover{
                cursor: pointer;
            }
        }
    }
}
.box_ti{
   margin-top:25px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:10px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}

.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
    padding-left: 30px;
}
</style>
